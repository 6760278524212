import React, { useEffect, useState } from "react"
import { graphql, Link } from 'gatsby';
import {
  Layout,
  SEO,
  PlanBnr,
  Access,
  ImgList,
  InfoTable,
  FacilityPostBtns,
  FacilityDNav,
  KitchenList,
  AmenitieList,
  FaqList,
  wovnCheck,
  Accodion,
} from '../../../components/_index';


// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter
  const [floorPdf, setFloorPdf] = useState('floorPlan_marutamachi.pdf')
  wovnCheck(function (wovnCode: string) {
    const pdffile = wovnCode !== 'ja' ? 'floorPlan_marutamachi_en.pdf' : 'floorPlan_marutamachi.pdf';
    setFloorPdf(pdffile)
  });
  return (
    <Layout>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <main id="facility_d" className="nishijin-villa under">
        <div id="facility_dMv">
          <div className="mvCont">
            <p className="ico">#7</p>
            <h2 className="facility_dMvTit">京の温所 丸太町<span>MARUTAMACHI</span></h2>
            <div className="mvImg" style={{ backgroundImage: 'url(/images/facility/20220611marumv.jpg)' }}></div>
          </div>
          <FacilityDNav data={[
            {
              anchor: "#facility_dInfo",
              label: <>施設情報</>,
            },
            {
              anchor: "#facility_dAmenitie",
              label: <>アメニティ</>,
            },
            {
              anchor: "#facility_dAccess",
              label: <>アクセス</>,
            },
            {
              anchor: "#facility_dPlan",
              label: <>プラン一覧</>,
            },
            {
              anchor: "#facility_dFaq",
              label: <>よくあるご質問</>,
            },
          ]} />
        </div>
        <div id="facility_dWrap" className="wd">
          <div id="facility_dInfoWrap">
            <section id="facility_dInfoAbout">
              <h2 className="tit">京町家で美とアートに触れる<br />時間と空間を共有できる女子旅の宿</h2>
              <p>「京の温所 丸太町」は京都御所のすぐそば、地下鉄丸太町駅から徒歩約2分という京都市内の中心地にありながら、静かな通りに面しています。<br />明治時代に建てられた京町家を当時の雰囲気は残しつつ、今の暮らしに寄り添うようにリノベーションしました。「トラフ建築設計事務所」がプロデュースしたユニークな家具や照明、美術作家 植田志保の描画などに囲まれて過ごせる京町家です。<br />また、一緒にお料理が楽しめるアイランドキッチンや、湯上り後も体がポカポカと温かい炭酸泉風呂、広々としたメイクアップスペース、寝る前の語らいのひとときのためのラウンジスペース。女子旅でのご利用はもちろん、男子旅やカップル、ご家族で自由な癒しのひとときをお過ごしください。</p>
              <FacilityPostBtns data={[
                {
                  href: `/assets/files/facility/${floorPdf}`,
                  blank: true,
                  label: <>間取り図<small>（PDF）</small></>,
                },
                {
                  href: "/news/recommend/#marutamachi",
                  blank: false,
                  label: <>周辺のおすすめ</>,
                },
              ]} />
            </section>
            <section id="facility_dInfo">
              <h2 className="tit">INFORMATION</h2>
              <p className="post">京の温所 丸太町 #7<br /><a href="https://goo.gl/maps/hhK6C2AKsuN5LBgo6" target="_blank">京都市中京区車屋町通夷川上る少将井御旅町341-2</a>
              </p>
              <InfoTable data={[
                {
                  label: <>広さ</>,
                  value: <>2階建て94平米（1棟貸切）</>,
                },
                {
                  label: <>定員</>,
                  value: <>1～4名</>,
                },
                {
                  label: <>部屋</>,
                  value: <>&lt;1階&gt; アイランドキッチン 和室（掘りごたつ） 庭 カフェスペース 風呂（人工炭酸泉） メイクアップコーナー（洗面） トイレ<br />
                    &lt;2階&gt; ラウンジスペース ベッドルーム 和室</>,
                },
                {
                  label: <>寝具</>,
                  value: <>シングルベッド2台　ふとん2組（3名よりご用意）</>,
                },
                {
                  label: <>設備</>,
                  value: <>エアコン、空気清浄機、洗濯機、床暖房</>,
                },
                {
                  label: <>テレビ</>,
                  value: <>あり（19インチ ポータブルタイプ）<br />※インターネット、機器等との接続不可</>,
                },
                {
                  label: <>スピーカー</>,
                  value: <>Bluetoothスピーカー</>,
                },
                {
                  label: <>Wi-Fi</>,
                  value: <>無料Wi-Fi（無線LAN）</>,
                },
                {
                  label: <>禁煙</>,
                  value: <>建物・敷地内　全面禁煙</>,
                },
              ]} />
            </section>
          </div>
          <div id="facility_dGallery" className="facility_dSection">
            <ImgList data={[
              '/images/facility/bfb7c7fa2d5563edf4b92e059acea544.jpg',
              '/images/facility/2fde9bfacfe9ac1d695808619c723359.jpg',
              '/images/facility/d20f421f40b96a4794352bf5c95bc9d4.jpg',
              '/images/facility/e145caf1e7c0b21a1228f5f9641e4a50.jpg',
              '/images/facility/c4b1d645cf8742d05ce252e3c931d7b2.jpg',
              '/images/facility/5566e70bf9a3fcca4b82d65b9dc5f6c4.jpg',
              '/images/facility/1aa5dc068297fc9e7cd87fb88b477992.jpg',
              '/images/facility/0174cf90c081ed203150fa9b9f3df722.jpg',
              '/images/facility/865971ec49e20b62b72906ff5e7e9216.jpg',
            ]} />
          </div>
          <section id="facility_dIntro" className="facility_d facility_dSection">
            <h2 className="facility_dTit">美とアートに触れる京町家</h2>
            <p>「京の温所 丸太町」一歩足を踏み入れると京町家ならではの高い吹き抜けに、大きな丸い行灯照明が出迎えます。<br />くつろぎの空間は「トラフ建築設計事務所」がプロデュース。一番印象的なのが2階のラウンジスペースです。大きさの異なるいくつもの丸い行灯と、京都の石庭を思わせるソファがラウンジスペースを演出します。ソファは絶妙な丸みと固さがここちよく、自由に動かすこともできます。すわったり寝転がったり、思い思いの形や姿で寝る前の語らいのひとときをお楽しみください。<br />あたたかな木肌の感触がここちよいカフェスペースのチェアや、機能性と美しさを兼ね備えたカウンターチェアなどは「カリモク家具」が製作しました。
            </p>
            <ImgList data={[
              '/images/facility/083047ffc0e6be08296f14a79bd2b237.jpg',
              '/images/facility/615cfb66ac7fe008c6c64aafc13abe10.jpg',
              '/images/facility/27253e055d3f86d0bf0cd51e219d32ed.jpg',
            ]} />
          </section>
          <section id="facility_dKitchen" className="facility_d facility_dSection">
            <div>
              <h2 className="facility_dTit">料理や食事が楽しくなるキッチン＆ダイニング</h2>
              <p>存在感あるアイランドキッチンは、4人で腰かけながら料理できる大きさ。広い人工大理石のカウンターでパンづくりや手打ちパスタも楽しめます。本格的な料理までつくれるように冷凍冷蔵庫、IHの三口コンロ、グリルにオーブンレンジ、トースターや炊飯器もそろっています。また用と美を兼ね備えた調理器具や、食器、カトラリーもご用意しています。<br />さらにお料理やお茶の味の決め手になる水は浄水器「クリンスイ<sup>（※1）</sup>」を設置しています。ごはんや出汁、お茶や珈琲の香りがより強く引き立ちます。<br />お料理ができたら和室の掘りごたつダイニングでほっこりと。美術作家 植田志保の色をテーマにした描画や、光を受けて刻々と表情を変えてゆく庭と共に、満ち足りた時間が流れていきます。
              </p>
            </div>
            <ImgList data={[
              '/images/facility/599bc7e42fe92b720fc82c9984c13e9b.jpg',
              '/images/facility/10e054dc3202e2294699073d5b214c5d.jpg',
              '/images/facility/b414bb4ff6d58b8dd4af66fce118c0cb.jpg',
            ]} />
            <Accodion data={{
              title: <>キッチン備品</>,
              contents: <div className="facility_dOtherList">
                <dl>
                  <dt>設備</dt>
                  <dd>
                    <ul>
                      <li>冷凍冷蔵庫</li>
                      <li>オーブンレンジ</li>
                      <li>浄水器</li>
                      <li>トースター</li>
                      <li>炊飯器</li>
                    </ul>
                  </dd>
                </dl>
                <dl>
                  <dt>鍋類</dt>
                  <dd>
                    <ul>
                      <li>鍋（ストウブ）</li>
                      <li>雪平鍋</li>
                      <li>フライパン</li>
                    </ul>
                  </dd>
                </dl>
                <dl>
                  <dt>ツール</dt>
                  <dd>
                    <ul>
                      <li>包丁</li>
                      <li>ペティナイフ</li>
                      <li>まな板</li>
                      <li>ザル</li>
                      <li>木べら</li>
                      <li>お玉</li>
                      <li>トング</li>
                      <li>フライ返し</li>
                      <li>ピーラー</li>
                      <li>ワインオープナー</li>
                      <li>麺棒</li>
                    </ul>
                  </dd>
                </dl>
                <dl>
                  <dt>食器類</dt>
                  <dd>
                    <ul>
                      <li>皿</li>
                      <li>小鉢</li>
                      <li>マグカップ</li>
                      <li>グラス類</li>
                      <li>急須・湯呑み</li>
                      <li>箸・スプーン・フォーク・ナイフ</li>
                    </ul>
                  </dd>
                </dl>
                <dl>
                  <dt>その他</dt>
                  <dd>
                    <ul>
                      <li>ペーパータオル</li>
                      <li>食器洗い用洗剤</li>
                      <li>スポンジ</li>
                    </ul>
                  </dd>
                </dl>
                <p><small>※ 調味料類のご用意はございません。</small></p>
              </div>
            }} />
          </section>
          <AmenitieList data={[
            '/images/facility/71c025052cdb983559000290859138ee.jpg',
            '/images/facility/amenities_bath.jpg',
            '/images/facility/0e216602435490e83149d6c96e531d3b.jpg',
          ]} text={<>木の感触がここちよい丸いヒノキ風呂は人工炭酸泉<sup>（※2）</sup>。浸かると肌の上に細かな泡が集まってきて、まるでラムネやシャンパンの中に入っているようなお湯は、湯上り後も体がポカポカと温かいのが特長です。<br /></>} />
          <section id="facility_dAccess">
            <Access data={{
              train: <>地下鉄烏丸線「丸太町」駅5番出口　徒歩約2分</>,
              bus: <>「烏丸丸太町」下車　徒歩約3分</>,
              taxi: "",
              parking: <>駐車場はございません。（近隣パーキング：徒歩5分圏内数か所あり）</>,
              img: "",
              maplink: "https://goo.gl/maps/hhK6C2AKsuN5LBgo6",
              map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d801.1201463764462!2d135.75964734580634!3d35.01536297940812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60010923b85d652f%3A0xe7b1c8e3f8e4fe53!2z5Lqs44Gu5rip5omAIOS4uOWkqueUuiAjNw!5e0!3m2!1sja!2sjp!4v1585222472310!5m2!1sja!2sjp",
            }} />
          </section>
          <div className="noteArea">
            <p><small>※1 「クリンスイ」は、三菱ケミカル・クリンスイ株式会社の登録商標です。</small></p>
            <p><small>※2 人工炭酸泉は、三菱ケミカルアクア・ソリューションズ株式会社の商品です。</small></p>
          </div>
          <section id="facility_dPlan">
            <PlanBnr roomcd="0000001671" />
          </section>
          <FaqList />
        </div>
      </main>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
